export const isBrowser = () => typeof window !== 'undefined'

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

export const stripLastBackslash = (slug) =>
  slug.slice(slug.length - 1) === '/' ? slug.slice(0, slug.length - 1) : slug

export const copyUrl = () =>
  isBrowser() ? navigator.clipboard.writeText(window.location.href) : null
