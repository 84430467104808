import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

function PersonContainer({ className }) {
  return (
    <div
      className={`absolute w-[60vw] h-full inset-y-0 right-0 overflow-hidden ${
        !!className ? className : ''
      }`}
    >
      <StaticImage
        className="!absolute bottom-0 my-0 mx-auto inset-x-0 max-h-[90%] !hidden lg:!block lg:translate-x-[20vh] xl:translate-x-[16rem]"
        objectFit="contain"
        src="../../images/person3.png"
        alt="person"
        quality={90}
      />
      <StaticImage
        className="!absolute bottom-0 my-0 mx-auto inset-x-0 max-h-[95%] !hidden lg:!block lg:-translate-x-[10vh] xl:-translate-x-[14rem] 2xl:-translate-x-[16rem]"
        objectFit="contain"
        src="../../images/person1.png"
        alt="person"
        quality={90}
      />
      <StaticImage
        className="!hidden !absolute bottom-0 my-0 mx-auto inset-x-0 max-h-full xl:!block"
        objectFit="contain"
        src="../../images/person2.png"
        alt="person"
        quality={90}
      />
    </div>
  )
}

export default PersonContainer
