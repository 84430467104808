import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import classNames from 'classnames'

import { subscriptionInit, validateSubscriptionForm } from '../functions/reducers'
import { useForm } from '../functions/hooks'

import Content from './Atoms/Content'
import { CheckBox } from './Sections/ContactForm'

import { labels, localeUrls } from '../langs/langs'

import logo from '../images/logo-wb.svg'
import brandly from '../images/brandly.svg'

const FooterHref = ({ children, to }) => (
  <Link to={to} className="block font-medium mb-4 hover:text-green duration-300">
    {children}
  </Link>
)

const Footer = ({ localeID }) => {
  const { errors, handleInputChange, Submit } = useForm(
    50,
    subscriptionInit,
    validateSubscriptionForm,
    localeID,
    localeUrls[localeID]['THX_SUB']
  )
  useEffect(() => {
    handleInputChange({ target: { name: 'yourLang', value: localeID.slice(0, 2) } })
  }, [localeID])
  return (
    <>
      <footer className="!bg-dark py-20 border-t border-white/10 print:hidden">
        <Content className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-[2fr_1fr_1fr_1fr]">
          <div>
            <img src={logo} alt="footer-logo" />
            <p className="text-white opacity-50 pt-8 pb-6 text-sm">
              {parse(labels[localeID]['FOOTER_TIPS'])}
            </p>
            <p className="relative inline-block max-w-full">
              <input
                type="text"
                className={`h-12 w-96 max-w-full text-white outline-none bg-white/10 placeholder:text-gray/50 pl-4 pr-12 border border-transparent rounded-none ${classNames(
                  { '!border-red': errors.yourMail }
                )}`}
                placeholder="Email"
                name="yourMail"
                onChange={handleInputChange}
              />
              <Submit
                className="absolute right-0 h-full w-12 hcenter top-0 justify-center cursor-pointer hover:bg-dark duration-500 group"
                title={
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.3497 17.5L11.9543 15.2148L10.5851 16.3628L10.029 13.9189L15.9587 8.72567L8.84882 12.8097L5.5 10.3935L18.5 6.5L15.3497 17.5Z"
                      className="fill-gray group-hover:fill-green duration-500"
                    />
                  </svg>
                }
              />
            </p>
            <CheckBox
              onChange={handleInputChange}
              name="privacy"
              hasError={errors.privacy}
              className="mt-4 text-white/50"
            >
              {labels[localeID]['CF_AGREE1']}{' '}
              <Link className="underline" to={localeUrls[localeID]['GDPR']}>
                {labels[localeID]['CF_AGREE2']}
              </Link>
              .
            </CheckBox>
          </div>
          <div className="hidden lg:block text-white">
            <FooterHref to={localeUrls[localeID]['HOME']}>{labels[localeID]['HOME']}</FooterHref>
            <FooterHref to={localeUrls[localeID]['SERVICES']}>
              {labels[localeID]['SERVICES']}
            </FooterHref>
            <FooterHref to={localeUrls[localeID]['NEWS']}>{labels[localeID]['NEWS']}</FooterHref>
            <FooterHref to={localeUrls[localeID]['ABOUT_US']}>
              {labels[localeID]['TEAM']}
            </FooterHref>
          </div>
          <div className="hidden lg:block text-white">
            <FooterHref to={localeUrls[localeID]['ABOUT_US']}>
              {labels[localeID]['CONTACT']}
            </FooterHref>
            <FooterHref to={localeUrls[localeID]['CLIENT_ZONE']}>
              {labels[localeID]['CZONE']}
            </FooterHref>
            <FooterHref to={localeUrls[localeID]['CTA']}>
              {labels[localeID]['WORK_W_US']}
            </FooterHref>
          </div>
          <div className="text-white/80 text-sm leading-8 md:text-right">
            <p>Tomášikova 50/E</p>
            <p>831 04 Bratislava</p>
            <p>Slovenská republika</p>
            <p>
              <a href="tel:+421232606511">Tel: +421(2) 32 606 511</a>
            </p>
            <p>
              <a href="mailto:office@ccstax.sk">office@ccstax.sk</a>
            </p>
          </div>
        </Content>
      </footer>
      <aside className="bg-black py-5 print:hidden">
        <Content className="hcenter text-white/80 text-sm flex-wrap gap-5">
          <span>
            © {new Date().getFullYear()} CCS Tax - {labels[localeID]['ALLRIGHTS']}
          </span>
          <div className="flex-auto" />
          <a target="_blank" href="https://www.behance.net/samuelmucha" className="hcenter">
            Branding and Webdesign by
            <img src={brandly} alt="brandly logo" />
          </a>
        </Content>
      </aside>
    </>
  )
}

export default Footer
