import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import classNames from 'classnames'

import { labels, localeUrls } from '../../langs/langs'

import { contactInit, validateContactForm } from '../../functions/reducers'
import { useForm } from '../../functions/hooks'

import checkedI from '../../images/checked.svg'
import checkedGI from '../../images/checked-green.svg'

export const CheckBox = ({ children, onChange, name, hasError, light, className, bgColor }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    onChange({
      target: {
        name: name,
        value: checked
      }
    })
  }, [checked])

  const checkBoxClasses = classNames(
    {
      'bg-[#3A3C50]': !light && !bgColor,
      'bg-[#E8E8EA]': light && !bgColor,
      'border border-red': hasError
    },
    bgColor
  )

  return (
    <div className={`hcenter text-sm ${classNames({ 'text-white': !light }, className)}`}>
      <div
        className={`${checkBoxClasses} w-6 h-6 hcenter justify-center cursor-pointer select-none`}
        onClick={() => setChecked((prev) => !prev)}
        style={{ backgroundColor: !!bgColor ? bgColor : undefined }}
      >
        {checked && <img src={light ? checkedI : checkedGI} />}
      </div>
      <div>{children}</div>
    </div>
  )
}

const ErrorMsg = ({ children }) => <p className="text-red text-sm">{children}</p>

const ContactForm = ({ localeID, light = true, className }) => {
  const { errors, handleInputChange, Submit } = useForm(
    10,
    contactInit,
    validateContactForm,
    localeID
  )
  return (
    <div id="form" className={`${classNames({ light })} grid gap-4 ${className}`}>
      <div className="grid md:grid-cols-2 gap-4">
        <input
          type="text"
          placeholder={labels[localeID]['CF_NAME']}
          name="yourName"
          onChange={handleInputChange}
          className={classNames({ hasError: errors.yourName })}
        />
        <input
          type="text"
          placeholder={labels[localeID]['CF_LAST']}
          name="yourLast"
          onChange={handleInputChange}
          className={classNames({ hasError: errors.yourLast })}
        />
      </div>
      <input
        type="text"
        placeholder={labels[localeID]['CF_MAIL']}
        name="yourMail"
        onChange={handleInputChange}
        className={classNames({ hasError: errors.yourMail })}
      />
      {!!errors.yourMail && <ErrorMsg>{labels[localeID]['ERR_MAIL']}</ErrorMsg>}
      <input
        type="text"
        placeholder={labels[localeID]['CF_PHONE']}
        name="yourPhone"
        onChange={handleInputChange}
        className={classNames({ hasError: errors.yourPhone })}
      />
      {!!errors.yourPhone && <ErrorMsg>{labels[localeID]['ERR_PHONE']}</ErrorMsg>}
      <textarea
        placeholder={labels[localeID]['CF_MSG']}
        name="yourMsg"
        onChange={handleInputChange}
        className={classNames('min-h-[10rem]', { hasError: errors.yourMsg })}
      />
      <CheckBox onChange={handleInputChange} name="privacy" hasError={errors.privacy} light={light}>
        {labels[localeID]['CF_AGREE1']}{' '}
        <Link className="underline" to={localeUrls[localeID]['GDPR']}>
          {labels[localeID]['CF_AGREE2']}
        </Link>
        .
      </CheckBox>
      <p className="text-xs text-dark-gray">
        This site is protected by reCAPTCHA and the Google{' '}
        <a className="underline" href="https://policies.google.com/privacy" target="_blank">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a className="underline" href="https://policies.google.com/terms" target="_blank">
          Terms of Service
        </a>{' '}
        apply.
      </p>{' '}
      <p>
        <Submit title={labels[localeID]['SUBMIT']} />
      </p>
    </div>
  )
}

export default ContactForm
