import React from 'react'

import Content from './Content'

function BreadCrumbs({ children }) {
  return (
    <div className="bg-light py-5 font-medium text-sm hidden sm:block">
      <Content className="hcenter gap-5">{children}</Content>
    </div>
  )
}

export default BreadCrumbs
