import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const rSVG = () => (
  <svg
    className="group-hover:scale-150 group-hover:translate-x-1 duration-500"
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
  >
    <path d="M1 1.5L5 5.5L1 9.5" stroke="currentColor" strokeWidth="1.5" />
  </svg>
)

export const lSVG = () => (
  <svg
    className="group-hover:scale-150 group-hover:-translate-x-1 duration-500"
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
  >
    <path d="M7 1L2 6L7 11" stroke="currentColor" strokeWidth="1.5" />
  </svg>
)

const ArrowLink = ({ to, children, className = '', span = false, anchor = false, ...props }) => {
  const classesCombined = `hcenter font-medium gap-2 group flex-[0_0_auto] select-none ${className}`
  if (span) {
    return (
      <span className={classesCombined} {...props}>
        <span>{children}</span>
        {rSVG()}
      </span>
    )
  } else if (anchor) {
    return (
      <AnchorLink to={to} className={classesCombined} {...props}>
        <span>{children}</span>
        {rSVG()}
      </AnchorLink>
    )
  } else {
    return (
      <Link to={to} className={classesCombined} {...props}>
        <span>{children}</span>
        {rSVG()}
      </Link>
    )
  }
}

export default ArrowLink
