import React from 'react'

function Content({ children, className }) {
  const insertClasses = !!className ? className : ''
  return (
    <div
      className={`relative max-w-[100rem] px-[1.25rem] md:px-[2.5rem] xl:px-[5rem] my-0 mx-auto inset-x-0 ${insertClasses}`}
    >
      {children}
    </div>
  )
}

export default Content
