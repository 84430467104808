import React from 'react'
import parse from 'html-react-parser'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { Fade } from 'react-awesome-reveal'

import { ArrowLink, Content } from '.'

import { labels, localeUrls } from '../../langs/langs'

import pointI from '../../images/point-blue.svg'

import softB from '../../images/software-blue.svg'
import chartB from '../../images/chart.svg'
import docuB from '../../images/docu.svg'
import textB from '../../images/textcloud-blue.svg'

const BonusesRow = ({ localeID, gray }) => {
  const buttonClasses = classNames({ 'bg-white': gray })
  return (
    <div className="py-20">
      <div className="flex">
        <h3 className="pb-8 font-bold text-3xl -tracking-[0.4px]">
          {labels[localeID]['BONUSES']},
          <br />
          <span className="font-medium text-lg no-underline">{labels[localeID]['BONUSES_C']}</span>
        </h3>
        <div className="flex-auto" />
        <ArrowLink
          className="text-blue font-medium text-lg"
          anchor={true}
          to={`${localeUrls[localeID]['SERVICES']}#${localeUrls[localeID]['BONUSES']}`}
        >
          {labels[localeID]['MORE_ABOUT_BONUSES']}
        </ArrowLink>
      </div>
      <div className="flex justify-between gap-8 flex-wrap">
        <Fade cascade={true} damping={0.15} triggerOnce={false}>
          <span className={`buttonD ${buttonClasses}`}>
            <img src={softB} />
            {labels[localeID]['BONUS_B_1']}
          </span>
          <span className={`buttonD ${buttonClasses}`}>
            <img src={chartB} />
            {labels[localeID]['BONUS_B_2']}
          </span>
          <span className={`buttonD ${buttonClasses}`}>
            <img src={docuB} />
            {labels[localeID]['BONUS_B_3']}
          </span>
          <span className={`buttonD ${buttonClasses}`}>
            <img src={textB} />
            {labels[localeID]['BONUS_B_4']}
          </span>
        </Fade>
      </div>
    </div>
  )
}

const OneOfServices = ({ localeID, icon, title, children, gray = false, id }) => {
  const containerClasses = classNames({ 'bg-light': gray })

  const renderChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return (
        <div className="flex gap-5 font-lg items-start">
          <img src={pointI} />
          {child}
        </div>
      )
    }
  })

  return (
    <div id={id} className={`py-24 sm:py-32 ${containerClasses}`}>
      <Content>
        <div className="hcenter gap-6 sm:gap-12 pb-20">
          <Fade>
            <img className="w-32" src={icon} />
          </Fade>
          <h2 className="text-3xl -tracking-[0.4px] font-bold">{parse(title)}</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-7 2xl:gap-x-32">
          <Fade cascade={true} damping={0.05} triggerOnce={false}>
            {renderChildren}
          </Fade>
        </div>
        <BonusesRow localeID={localeID} gray={gray} />
        <Fade>
          <Link to={localeUrls[localeID]['CTA']} className="button2">
            {labels[localeID]['WORK_W_US']}
          </Link>
        </Fade>
      </Content>
    </div>
  )
}

export default OneOfServices
