import React, { useState } from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import parse from 'html-react-parser'
import classNames from 'classnames'

import Content from './Atoms/Content'
import DropDown from './Atoms/DropDown'

import { labels, localeUrls, shortCuts } from '../langs/langs'
import { getKeyByValue, stripLastBackslash } from '../functions/functions'

import logo from '../images/logo.svg'
import chart from '../images/chart.svg'
import docu from '../images/docu.svg'
import down from '../images/down.svg'

import gper from '../images/grad-percentage.svg'
import garr from '../images/grad-arrow.svg'
import geur from '../images/grad-euro.svg'
import gcir from '../images/grad-circle.svg'

import navb1 from '../images/navImg1.jpg'
import navb2 from '../images/navImg2.jpg'
import navb3 from '../images/navImg3.jpg'
import navb4 from '../images/navImg4.jpg'

const ServicesMenu = ({ children, icon, bckg, to }) => (
  <AnchorLink
    to={to}
    className="servmenu hcenter overflow-hidden relative py-8 px-12 gap-6 justify-center text-sm 2xl:text-lg"
  >
    <img
      src={bckg}
      className="absolute duration-500 object-cover -z-10 object-center inset-overflow min-h-overflow min-w-overflow"
    />
    <img src={icon} className="duration-500" />
    <span>{children}</span>
  </AnchorLink>
)

const LangDropDown = ({ localeID, translatedSlug, currentPath }) => {
  const translateUrlKey = getKeyByValue(localeUrls[localeID], stripLastBackslash(currentPath))

  const getTranslationUrl = (langKey) =>
    !!translatedSlug?.[langKey]
      ? translatedSlug[langKey]
      : localeUrls[langKey][!!translateUrlKey ? translateUrlKey : 'HOME']

  const currentShortCuts = { ...shortCuts }
  const title = currentShortCuts[localeID]
  delete currentShortCuts[localeID]

  const list = []
  for (let key in currentShortCuts) {
    if (currentShortCuts.hasOwnProperty(key)) {
      list.push({ title: currentShortCuts[key], href: getTranslationUrl(key) })
    }
  }

  return (
    <DropDown title={title}>
      {list.map((item) => (
        <li className="my-3.5" key={item.href}>
          <Link href={item.href}>{item.title}</Link>
        </li>
      ))}
    </DropDown>
  )
}

function Nav({ localeID, currentPath, translatedSlug }) {
  const [navOpened, setNavOpened] = useState(false)

  const navClasses = classNames({ '-translate-y-full': !navOpened, 'transform-none': navOpened })

  return (
    <header className="h-navh bg-white fixed top-0 w-full z-50 select-none print:hidden">
      <div className="absolute bottom-0 inset-x-0 h-px bg-gray z-10" />
      <div className="absolute inset-0 bg-white" />

      <Content className="hcenter h-full bg-white xl:gap-0">
        <Link to={localeUrls[localeID]['HOME']}>
          <img src={logo} alt="logo ccstax" />
        </Link>
        <nav
          className={`duration-500 top-full absolute w-full bg-white left-0 h-[calc(100vh_-_3.75rem)] hcenter flex-col gap-8 py-10 -z-10 overflow-y-auto ${navClasses} 
          xl:static xl:h-full xl:py-0 xl:transform-none xl:flex-row xl:top-0 xl:overflow-visible xl:ml-16 xl:w-auto xl:flex-auto xl:gap-4 xl:z-auto`}
        >
          <span className="xl:h-full font-medium xl:mr-4 hcenter gap-1 group lg:hover:text-blue transition-all duration-300">
            <Link to={localeUrls[localeID]['SERVICES']}>{labels[localeID]['SERVICES']}</Link>{' '}
            <img src={down} className="duration-500 group-hover:rotate-180 hidden xl:block" />
            <div
              className={`fixed w-100 hidden xl:grid grid-cols-4 inset-x-0 text-white font-medium -z-40 bottom-full duration-500 
              group-hover:translate-y-[calc(100%_+_3.75rem)]`}
            >
              <ServicesMenu
                icon={gper}
                bckg={navb1}
                to={`${localeUrls[localeID]['SERVICES']}#${localeUrls[localeID]['SERVICES_TC']}`}
              >
                {parse(labels[localeID]['SER_1'])}
              </ServicesMenu>
              <ServicesMenu
                icon={garr}
                bckg={navb2}
                to={`${localeUrls[localeID]['SERVICES']}#${localeUrls[localeID]['SERVICES_CA']}`}
              >
                {parse(labels[localeID]['SER_2'])}
              </ServicesMenu>
              <ServicesMenu
                icon={geur}
                bckg={navb3}
                to={`${localeUrls[localeID]['SERVICES']}#${localeUrls[localeID]['SERVICES_PA']}`}
              >
                {parse(labels[localeID]['SER_3'])}
              </ServicesMenu>
              <ServicesMenu
                icon={gcir}
                bckg={navb4}
                to={`${localeUrls[localeID]['SERVICES']}#${localeUrls[localeID]['SERVICES_AS']}`}
              >
                {parse(labels[localeID]['SER_4'])}
              </ServicesMenu>
            </div>
          </span>
          <Link
            to={localeUrls[localeID]['NEWS']}
            className="font-medium xl:mr-4 lg:hover:text-blue transition-all duration-300"
          >
            {labels[localeID]['NEWS']}
          </Link>
          <Link
            to={localeUrls[localeID]['ABOUT_US']}
            className="font-medium xl:mr-4 lg:hover:text-blue transition-all duration-300"
          >
            {labels[localeID]['TEAM']}
          </Link>
          <Link
            to={localeUrls[localeID]['CONTACT']}
            className="font-medium xl:mr-4 lg:hover:text-blue transition-all duration-300"
          >
            {labels[localeID]['CONTACT']}
          </Link>
          <div className="flex-auto hidden xl:block" />
          <Link className="button xl:hidden" to={localeUrls[localeID]['CLIENT_ZONE']}>
            {labels[localeID]['CZONE']}
          </Link>
          <DropDown
            title={labels[localeID]['CZONE']}
            className="hidden xl:flex"
            titleClasses="button gap-2.5"
            center={true}
            to={localeUrls[localeID]['CLIENT_ZONE']}
          >
            <li className="my-3.5 px-3 whitespace-nowrap inline-block">
              <Link className="hcenter gap-1">
                <img src={chart} />
                {labels[localeID]['DIGI_REP']}
              </Link>
            </li>
            <li className="my-3.5 px-3 whitespace-nowrap inline-block">
              <a className="hcenter gap-1" href="https://dataroom.ccstax.sk/" target="_blank" rage>
                <img src={docu} />
                {labels[localeID]['CLOUD']}
              </a>
            </li>
          </DropDown>
          <Link to={localeUrls[localeID]['CTA']} className="button2">
            {labels[localeID]['WORK_W_US']}
          </Link>
        </nav>
        <div className="flex-auto xl:hidden" />
        <LangDropDown
          localeID={localeID}
          translatedSlug={translatedSlug}
          currentPath={currentPath}
        />
        <div
          id="menuButton"
          onClick={() => setNavOpened((p) => !p)}
          className={navOpened ? 'clicked' : ''}
        >
          <div>
            <div className="bar f"></div>
            <div className="bar s"></div>
            <div className="bar t"></div>
          </div>
        </div>
      </Content>
    </header>
  )
}

export default Nav
