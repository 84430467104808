import axios from 'axios';

const baseURL = process.env.GATSBY_WP_BASE_URL;

export const prepareFormData = data => {
    const bodyFormData = new FormData();
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            if (key == 'yourFiles') {
                for (const file in data[key]) {
                    bodyFormData.append(key + '[]', data[key][file]);
                }
            } else {
                bodyFormData.append(key, data[key]);
            }
        }
    }
    return bodyFormData
}

export const formSubmitUrl = id => `wp-json/contact-form-7/v1/contact-forms/${id}/feedback`

export const dataAxios = axios.create({
    baseURL: baseURL,
});