import React from 'react'

import Nav from './Nav'
import Footer from './Footer'
import Seo from './Seo'

const Layout = ({ children, localeID = 'sk_SK', location, title, translatedSlug }) => {
  return (
    <>
      <Seo title={title} />
      <Nav
        localeID={localeID}
        location={location}
        currentPath={location?.pathname}
        translatedSlug={translatedSlug}
      />
      <main className="mt-[3.75rem]">{children}</main>
      <Footer localeID={localeID} />
    </>
  )
}

export default Layout
