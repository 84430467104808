import React from 'react'
import { useReducer, useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'

import { formReducer } from '../functions/reducers'
import { isBrowser } from './functions'
import { dataAxios, formSubmitUrl, prepareFormData } from '../apis/axios'

import { localeUrls } from '../langs/langs'

import loadGif from '../images/162.gif'

const SubmitButton = ({ handleSubmit, submitting, title, className }) => {
  return (
    <button className={!!className ? className : 'button3'} onClick={handleSubmit}>
      {submitting ? <img className="max-h-[80%]" src={loadGif} /> : title}
    </button>
  )
}

export function useForm(
  formId,
  initData,
  validate,
  localeID,
  thxUrl = '',
  abortFunc = () => {},
  customSubmitUrl = ''
) {
  useEffect(() => {
    if (isBrowser() && typeof window.grecaptcha === 'undefined') {
      loadExternalScript(() => {},
      `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_KEY}`)
    }
  }, [])

  const [form, dispatchForm] = useReducer(formReducer, initData)
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const handleInputChange = (event) => {
    dispatchForm({
      name: event.target.name,
      value: event.target.value
    })
  }

  const handleSubmit = function () {
    if (!submitting) {
      const status = validate(form)
      if (!status.valid) {
        setErrors(status.errors)
        return
      }
      setErrors({})
      if (isBrowser()) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.GATSBY_RECAPTCHA_KEY, { action: 'submit' })
            .then((token) => {
              submitForm(token)
            })
        })
      }
    }
  }

  const submitForm = async (token) => {
    setSubmitting(true)
    const data = prepareFormData({ ...form, _wpcf7_recaptcha_response: token })
    try {
      const url = !formId ? customSubmitUrl : formSubmitUrl(formId)
      const response = await dataAxios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log(response)
      if (response.data?.status == 'mail_sent') {
        navigate(!!thxUrl ? thxUrl : localeUrls[localeID]['THX'], { state: { done: true } })
      } else if (response.data?.status == 'aborted') {
        abortFunc(response.data?.message)
        setSubmitting(false)
      }
    } catch (e) {
      console.log(e)
      setSubmitting(false)
    }
  }

  const Submit = ({ title, className }) => (
    <SubmitButton
      handleSubmit={handleSubmit}
      submitting={submitting}
      title={title}
      className={className}
    />
  )

  return { form, errors, setErrors, handleInputChange, handleSubmit, Submit }
}

export const loadExternalScript = (callback, src) => {
  const script = document.createElement('script')
  script.src = src
  document.body.appendChild(script)
  script.onload = () => {
    if (callback) callback()
  }
}
