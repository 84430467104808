import React from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import par1 from '../../images/partners/Dürr_AG_logo.png'
import par2 from '../../images/partners/Raiffeisen_Bank.png'
import par3 from '../../images/partners/Obi.png'
import par4 from '../../images/partners/Logo_Ursa.png'
import par5 from '../../images/partners/logo_invert.png'
import par6 from '../../images/partners/HAVI.png'
import par7 from '../../images/partners/1200px-Habau_Logo.png'
import par8 from '../../images/partners/voestalpine.png'
import par9 from '../../images/partners/SYNLAB.png'
import par10 from '../../images/partners/assa-abloy-logo.png'

function PartnerSlider() {
  const sliderSettings = {
    dots: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: true,
    slidesToShow: 6,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }
  return (
    <div id="partnerSlider" className="py-9 bg-light">
      <Slider {...sliderSettings}>
        <img src={par1} />
        <img src={par2} />
        <img src={par3} />
        <img src={par4} />
        <img src={par5} />
        <img src={par6} />
        <img src={par7} />
        <img src={par8} />
        <img src={par9} />
        <img src={par10} />
      </Slider>
    </div>
  )
}

export default PartnerSlider
