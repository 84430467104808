import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

function DropDown({ children, className, title, titleClasses, center = false, to }) {
  const [opened, setOpened] = useState(false)

  const dgClasses = classNames({ 'top-[-200%]': !opened, 'top-full xl:-top-full': opened })
  const dgIconClasses = classNames('ddIcon', {
    'rotate-0': !opened,
    'rotate-180 xl:rotate-0': opened
  })
  const basicClasses = classNames({ 'pl-3.5 pr-1': !center, 'px-1': center })

  const innerBody = (
    <>
      {title}{' '}
      <svg
        className={`${dgIconClasses} xl:group-hover:rotate-180`}
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
      >
        <path d="M1.5 4L5.5 8L9.5 4" stroke="currentColor" strokeWidth="1.5" />
      </svg>
    </>
  )

  return (
    <div
      className={`h-full relative hcenter font-medium mr-3 xl:mr-0 ${basicClasses} ${className} group`}
    >
      {!!to ? (
        <Link
          className={`relative gap-1 hcenter cursor-pointer z-50 ${titleClasses}`}
          onClick={() => setOpened((p) => !p)}
          to={to}
        >
          {innerBody}
        </Link>
      ) : (
        <span
          className={`relative gap-1 hcenter cursor-pointer z-50 ${titleClasses}`}
          onClick={() => setOpened((p) => !p)}
        >
          {innerBody}
        </span>
      )}
      <ul
        className={`absolute bg-white min-w-full inline-flex w-max text-center duration-500 shadow-dropdown -z-10 flex-col left-2/4 -translate-x-2/4
  ${dgClasses} xl:group-hover:top-full`}
      >
        {children}
      </ul>
    </div>
  )
}

export default DropDown
