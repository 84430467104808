import React from 'react'

import Content from './Content'

export const heroClasses = 'bg-dark lg:h-[calc(100vh_-_3.75rem)] min-h-max py-16'
export const heroContainerClasses =
  'text-white grid h-full grid-cols-1 sm:grid-cols-2 gap-12 lg:gap-20 2xl:gap-36'

function HeroGrid({ children, className, contentClassName }) {
  return (
    <div className={`${heroClasses} max-h-[52rem] ${className}`}>
      <Content className={`${heroContainerClasses} ${contentClassName}`}>{children}</Content>
    </div>
  )
}

export default HeroGrid
