import enLabels from './en.json'
import skLabels from './sk.json'
import deLabels from './de.json'

import enUrls from './en-urls.json'
import skUrls from './sk-urls.json'
import deUrls from './de-urls.json'

export const labels = {
  en_US: enLabels,
  sk_SK: skLabels,
  de_DE: deLabels
}
export const localeUrls = {
  en_US: enUrls,
  sk_SK: skUrls,
  de_DE: deUrls
}
export const shortCuts = {
  en_US: 'EN',
  sk_SK: 'SK',
  de_DE: 'DE'
}
