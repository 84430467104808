export const formReducer = (state, action) => {
  return {
    ...state,
    [action.name]: action.value
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////
export const contactInit = {
  yourName: '',
  yourLast: '',
  yourPhone: '',
  yourMail: '',
  yourMsg: '',
  privacy: false
}

export const validateContactForm = (data) => {
  const status = {
    valid: 1,
    errors: {}
  }
  if (!data.yourName) {
    status.valid = 0
    status.errors.yourName = 1
  }
  if (!data.yourLast) {
    status.valid = 0
    status.errors.yourLast = 1
  }
  if (!data.yourMsg) {
    status.valid = 0
    status.errors.yourMsg = 1
  }
  if (!/^[\+][0-9]{8,}$/.test(data.yourPhone)) {
    status.valid = 0
    status.errors.yourPhone = 1
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.yourMail)) {
    status.valid = 0
    status.errors.yourMail = 1
  }
  if (!data.privacy) {
    status.valid = 0
    status.errors.privacy = 1
  }

  return status
}

export const subscriptionInit = {
  yourMail: '',
  yourLang: 'sk_SK',
  privacy: false
}

export const validateSubscriptionForm = (data) => {
  const status = {
    valid: 1,
    errors: {}
  }
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.yourMail)) {
    status.valid = 0
    status.errors.yourMail = 1
  }
  if (!data.privacy) {
    status.valid = 0
    status.errors.privacy = 1
  }

  return status
}
