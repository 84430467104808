import React from 'react'
import classNames from 'classnames'

export const childStyles = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0
}

const FloatingCircle = ({ className, basic = true }) => {
  const basicClasses = classNames({
    'h-52 w-52 lg:w-96 lg:h-96 2xl:w-[30rem] 2xl:h-[30rem]': basic
  })
  return (
    <div
      className={`rounded-full absolute opacity-60 lg:opacity-100 ${basicClasses} ${className}`}
    />
  )
}
export default FloatingCircle
