import React, { useRef } from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Content from './Content'

import personIcon from '../../images/person-white.svg'

import phoneI from '../../images/phone-blue.svg'
import mobileI from '../../images/mobile.svg'
import atI from '../../images/at-blue.svg'
import emploI from '../../images/employ-card-blue.svg'
import { labels } from '../../langs/langs'

import ArrowLink from './ArrowLink'
import classNames from 'classnames'

export const teamSliderCardImgProps = {
  className: 'h-full w-full',
  placeholder: 'blurred',
  objectFit: 'cover'
}

export const TeamSliderCard = ({
  degree,
  name,
  subtitle,
  position,
  positionIcon,
  phone,
  mail,
  mobile,
  cardUrl,
  children,
  localeID
}) => {
  return (
    <div className="shadow-dropdown">
      <div
        className={classNames(
          'relative bg-blue sm:h-60 2xl:h-72 hcenter justify-center overflow-hidden',
          { 'pt-[100%] sm:pt-0': !children }
        )}
      >
        {!!children ? (
          children
        ) : (
          <img src={personIcon} className="h-24 w-24 inset-0 absolute m-auto" />
        )}
      </div>
      <div className="bg-white p-7">
        {!!position && (
          <p className="hcenter text-blue text-xs font-bold pb-5 2xl:text-sm">
            <img src={positionIcon} />
            {position}
          </p>
        )}
        <p className="text-xs">{degree}</p>
        <p className="font-medium text-lg 2xl:text-xl">{name}</p>
        <p className="text-sm text-dark-gray pb-5">{subtitle}</p>
        <a className="hcenter font-medium text-sm" href={`tel:${phone}`}>
          <img src={phoneI} />
          {phone}
        </a>
        {!!mobile && (
          <a className="hcenter font-medium text-sm pt-2" href={`tel:${mobile}`}>
            <img src={mobileI} />
            {mobile}
          </a>
        )}
        <a className="hcenter font-medium text-sm pt-2" href={`mailto:${mail}`}>
          <img src={atI} />
          {mail}
        </a>
        {!!cardUrl && (
          <a className="hcenter font-medium text-sm pt-2" href={cardUrl}>
            <img src={emploI} />
            {labels[localeID]['EMPL_CARD']}
          </a>
        )}
      </div>
    </div>
  )
}

function TeamSlider({
  title,
  bg = false,
  localeID,
  children,
  titleClasses,
  showAllUrl,
  arrowClasses
}) {
  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    slidesToShow: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const renderedChildren = function () {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { localeID })
      }
    })
  }

  const thisSlider = useRef()

  return (
    <div className="relative overflow-hidden">
      {bg && <div className="absolute bg-dark top-0 inset-x-0 h-[23.5rem] 2xl:h-[26.5rem]" />}
      {!!title && (
        <Content className="pt-12">
          <div className={`hcenter h-10 gap-4 sm:gap-10 ${titleClasses}`}>
            {title}
            <div className="flex-auto" />
            <div className={`hcenter ${arrowClasses}`}>
              <svg
                width="15"
                height="22"
                viewBox="0 0 15 22"
                fill="none"
                onClick={() => thisSlider.current.slickPrev()}
                className="cursor-pointer select-none duration-500 hover:scale-150 hover:-translate-x-1"
              >
                <path d="M10 6L5 11L10 16" stroke="currentColor" strokeWidth="1.5" />
              </svg>
              <svg
                width="15"
                height="22"
                viewBox="0 0 15 22"
                fill="none"
                onClick={() => thisSlider.current.slickNext()}
                className="cursor-pointer select-none duration-500 hover:scale-150 hover:translate-x-1"
              >
                <path d="M5 6L10 11L5 16" stroke="currentColor" strokeWidth="1.5" />
              </svg>
            </div>
            {!!showAllUrl && (
              <ArrowLink to={showAllUrl} className="text-green">
                {labels[localeID]['SHOW_ALL']}
              </ArrowLink>
            )}
          </div>
        </Content>
      )}
      <Content className="card-slider pt-12 relative mb-12">
        <Slider {...sliderSettings} ref={thisSlider}>
          {renderedChildren()}
        </Slider>
      </Content>
    </div>
  )
}

export default TeamSlider
